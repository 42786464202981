export const PAGES = {
  '/': '/',
  '/400/': '/400/',
  '/about-more/': '/about-more/',
  '/about/': '/about/',
  '/login/': '/login/',
  '/sign-up/': '/sign-up/',
  '/profile/': '/profile/',
  '/articles/': '/articles/',
  '/articles/typing-practice-paragraphs/': '/articles/typing-practice-paragraphs/',
  '/articles/typing-practice-quotes/': '/articles/typing-practice-quotes/',
  '/articles/number-typing-practice/': '/articles/number-typing-practice/',
  '/articles/online-typing-practice/': '/articles/online-typing-practice/',
  '/articles/1-minute-typing-test/': '/articles/1-minute-typing-test/',
  '/articles/right-hand-typing-practice/': '/articles/right-hand-typing-practice/',
  '/articles/left-hand-typing-practice/': '/articles/left-hand-typing-practice/',
  '/articles/keystrokes-per-hour-test/': '/articles/keystrokes-per-hour-test/',
  '/articles/how-fast-can-you-type-the-alphabet/': '/articles/how-fast-can-you-type-the-alphabet/',
  '/contact-us/': '/contact-us/',
  '/features/': '/features/',
  '/help/': '/help/',
  '/knowledge/': '/knowledge/',
  '/lessons/': '/lessons/',
  '/parents-guide/': '/parents-guide/',
  '/privacy/': '/privacy/',
  '/stats/': '/stats/',
  '/terms-of-service/': '/terms-of-service/',
  '/thank-you/': '/thank-you/',
  '/typing-practice/': '/typing-practice/',
  '/typing-test/': '/typing-test/',
  '/why-typing-mentor/': '/why-typing-mentor/',
  '/ask-chat-gpt/': '/ask-chat-gpt/',
  '/typing-mentor-coach/': '/typing-mentor-coach/',
  '/typing-mentor-coach-terms/': '/typing-mentor-coach-terms/',
}

export const SECTIONS = {
  ergonomicTyping: {
    id: 'ergonomicTyping',
    get link() {
      return `/knowledge#${this.id}`
    },
  },
  userGuide: {
    id: 'intro-1',
    get link() {
      return `/help/#${this.id}`
    },
  },
  stats: {
    yourFingers: {
      id: 'stats-your-fingers',
      get link() {
        return `/stats/#${this.id}`
      },
    },
  },
}
