import { MS_IN_5_MINUTES, MS_IN_10_MINUTES, MS_IN_15_MINUTES, MS_IN_MINUTE } from 'constants/calculations'

export type LESSON_NAME = keyof typeof LESSON_NAMES

/**
 * STEPS: for adding a new lesson

 * 1. Add the lesson name in the list below
 * 2. Add the lesson duration below
 * 3. Add the lesson title below in LESSON_TITLE
 * 4. Add the same on studio/schemas/constants.js and deploy if it is used for a CTA
 * 5. Add the lesson web\src\pages\api\copy\index.ts:80
 * 6. Add the lesson on exclusion if needed LESSON_PAGE_EXCLUSION below
 * 7. Add the lesson in web\src\store\sagas\handlers\copy.ts for LESSON MASKING if the lesson is PARAGRAPH based 
 */

// HIGHLIGHT: we need to maintain the same list on studio/schemas/constants.js
export enum LESSON_NAMES {
  PARAGRAPH = 'PARAGRAPH',
  UPPERCASE = 'UPPERCASE',
  LOWERCASE = 'LOWERCASE',
  NUM = 'NUM',
  PUNCTUATION = 'PUNCTUATION',
  QUOTES = 'QUOTES',
  H_LEFT = 'H_LEFT',
  H_RIGHT = 'H_RIGHT',
  NG_BI = 'NG_BI',
  NG_TRI = 'NG_TRI',
  NG_4 = 'NG_4',
  R_NUM = 'R_NUM',
  R_TOP = 'R_TOP',
  R_HOME = 'R_HOME',
  R_BOTTOM = 'R_BOTTOM',
  F_L_PINKY = 'F_L_PINKY',
  F_L_RING = 'F_L_RING',
  F_L_MID = 'F_L_MID',
  F_L_INDEX = 'F_L_INDEX',
  F_R_PINKY = 'F_R_PINKY',
  F_R_RING = 'F_R_RING',
  F_R_MID = 'F_R_MID',
  F_R_INDEX = 'F_R_INDEX',
  CW_1_50 = 'CW_1_50',
  CW_51_100 = 'CW_51_100',
  CW_101_150 = 'CW_101_150',
  CW_151_200 = 'CW_151_200',
  CW_201_250 = 'CW_201_250',
  CW_251_300 = 'CW_251_300',
  CW_301_400 = 'CW_301_400',
  CW_401_500 = 'CW_401_500',
  CW_501_600 = 'CW_501_600',
  CW_601_700 = 'CW_601_700',
  CW_701_800 = 'CW_701_800',
  CW_801_900 = 'CW_801_900',
  CW_901_1000 = 'CW_901_1000',

  LONG_WORD_H_LEFT = 'LONG_WORD_H_LEFT',
  LONG_WORD_H_RIGHT = 'LONG_WORD_H_RIGHT',

  // use the paragraphs for one min typing test. Do not request server for ONE_MIN text.
  ONE_MIN = 'ONE_MIN',
  FIVE_MIN = 'FIVE_MIN',
  TEN_MIN = 'TEN_MIN',
  FIFTEEN_MIN = 'FIFTEEN_MIN',

  // alphabet typing test
  ALPHABET = 'ALPHABET',

  // remove the following quote lessons after finding out a better way to
  // generate them. Making each quote a different lesson name seems non-optimal.
  M_DUNN = 'M_DUNN',
  A_LINCOLN = 'A_LINCOLN',
  M_ANGELOU = 'M_ANGELOU',
  M_TERESA = 'M_TERESA',
  J_LENNON = 'J_LENNON',
  N_MENDELA = 'N_MENDELA',
  CONFUCIUS = 'CONFUCIUS',
  T_A_EDISON = 'T_A_EDISON',
  H_D_THOREAU = 'H_D_THOREAU',
  W_S_CHURCHILL = 'W_S_CHURCHILL',
  D_BRINKLEY = 'D_BRINKLEY',
  G_CHAHAL = 'G_CHAHAL',

  AI = 'AI',
}

const DEFAULT_DURATION = MS_IN_MINUTE
const WITHOUT_DURATION = 0

export const LESSON_DURATION: Record<LESSON_NAMES, number> = {
  PARAGRAPH: WITHOUT_DURATION,
  UPPERCASE: DEFAULT_DURATION,
  LOWERCASE: DEFAULT_DURATION,
  NUM: DEFAULT_DURATION,
  PUNCTUATION: DEFAULT_DURATION,
  QUOTES: DEFAULT_DURATION,
  H_LEFT: DEFAULT_DURATION,
  H_RIGHT: DEFAULT_DURATION,
  NG_BI: DEFAULT_DURATION,
  NG_TRI: DEFAULT_DURATION,
  NG_4: DEFAULT_DURATION,
  R_NUM: DEFAULT_DURATION,
  R_TOP: DEFAULT_DURATION,
  R_HOME: DEFAULT_DURATION,
  R_BOTTOM: DEFAULT_DURATION,
  F_L_PINKY: DEFAULT_DURATION,
  F_L_RING: DEFAULT_DURATION,
  F_L_MID: DEFAULT_DURATION,
  F_L_INDEX: DEFAULT_DURATION,
  F_R_PINKY: DEFAULT_DURATION,
  F_R_RING: DEFAULT_DURATION,
  F_R_MID: DEFAULT_DURATION,
  F_R_INDEX: DEFAULT_DURATION,
  CW_1_50: DEFAULT_DURATION,
  CW_51_100: DEFAULT_DURATION,
  CW_101_150: DEFAULT_DURATION,
  CW_151_200: DEFAULT_DURATION,
  CW_201_250: DEFAULT_DURATION,
  CW_251_300: DEFAULT_DURATION,
  CW_301_400: DEFAULT_DURATION,
  CW_401_500: DEFAULT_DURATION,
  CW_501_600: DEFAULT_DURATION,
  CW_601_700: DEFAULT_DURATION,
  CW_701_800: DEFAULT_DURATION,
  CW_801_900: DEFAULT_DURATION,
  CW_901_1000: DEFAULT_DURATION,

  LONG_WORD_H_LEFT: DEFAULT_DURATION,
  LONG_WORD_H_RIGHT: DEFAULT_DURATION,

  ONE_MIN: MS_IN_MINUTE,
  FIVE_MIN: MS_IN_5_MINUTES,
  TEN_MIN: MS_IN_10_MINUTES,
  FIFTEEN_MIN: MS_IN_15_MINUTES,

  ALPHABET: WITHOUT_DURATION,

  M_DUNN: DEFAULT_DURATION,
  A_LINCOLN: DEFAULT_DURATION,
  M_ANGELOU: DEFAULT_DURATION,
  M_TERESA: DEFAULT_DURATION,
  J_LENNON: DEFAULT_DURATION,
  N_MENDELA: DEFAULT_DURATION,
  CONFUCIUS: DEFAULT_DURATION,
  T_A_EDISON: DEFAULT_DURATION,
  H_D_THOREAU: DEFAULT_DURATION,
  W_S_CHURCHILL: DEFAULT_DURATION,
  D_BRINKLEY: DEFAULT_DURATION,
  G_CHAHAL: DEFAULT_DURATION,

  AI: WITHOUT_DURATION,
}

export const LESSON_TITLE: Record<LESSON_NAMES, string> = {
  AI: 'AI Guided Lessons',

  PARAGRAPH: 'Paragraph',
  UPPERCASE: 'Uppercase',
  LOWERCASE: 'Lowercase',
  NUM: 'Number',
  PUNCTUATION: 'Punctuations',
  CW_1_50: 'Most Common 50 Words',
  CW_51_100: 'Most Common 51 to 100 Words',
  CW_101_150: 'Most Common 101 to 150 Words',
  CW_151_200: 'Most Common 151 to 200 Words',
  CW_201_250: 'Most Common 201 to 250 Words',
  CW_251_300: 'Most Common 251 to 300 Words',
  CW_301_400: 'Most Common 301 to 400 Words',
  CW_401_500: 'Most Common 401 to 500 Words',
  CW_501_600: 'Most Common 501 to 600 Words',
  CW_601_700: 'Most Common 601 to 700 Words',
  CW_701_800: 'Most Common 701 to 800 Words',
  CW_801_900: 'Most Common 801 to 900 Words',
  CW_901_1000: 'Most Common 901 to 1000 Words',
  QUOTES: 'Famous Quotes',
  H_LEFT: 'Left Hand',
  H_RIGHT: 'Right Hand',
  NG_BI: 'N-grams of 2 characters',
  NG_TRI: 'N-grams of 3 characters',
  NG_4: 'N-grams of 4 characters',
  R_NUM: 'Number Row',
  R_TOP: 'Top Row',
  R_HOME: 'Home Row',
  R_BOTTOM: 'Bottom Row',
  F_L_PINKY: 'Left Pinky Finger',
  F_L_RING: 'Left Ring Finger',
  F_L_MID: 'Left Middle Finger',
  F_L_INDEX: 'Left Index Finger',
  F_R_PINKY: 'Right Pinky Finger',
  F_R_RING: 'Right Ring Finger',
  F_R_MID: 'Right Middle Finger',
  F_R_INDEX: 'Right Index Finger',

  LONG_WORD_H_LEFT: 'Longest left hand words',
  LONG_WORD_H_RIGHT: 'Longest right hand words',

  ONE_MIN: 'One Minute Typing Test',
  FIVE_MIN: 'Five Minute Typing Test',
  TEN_MIN: 'Ten Minute Typing Test',
  FIFTEEN_MIN: 'Fifteen Minute Typing Test',
  ALPHABET: 'Type the alphabet',

  M_DUNN: 'Mark Dunn featured in Ella Minnow Pea',
  A_LINCOLN: 'Quote from Abraham Lincoln',
  M_ANGELOU: 'Quote from Maya Angelou',
  M_TERESA: 'Quote from Mother Teresa ',
  J_LENNON: 'Quote from John Lennon',
  N_MENDELA: 'Quote from Nelson Mandela',
  CONFUCIUS: 'Quote from Confucius',
  T_A_EDISON: 'Quote from Thomas A. Edison',
  H_D_THOREAU: 'Quote from Henry David Thoreau',
  W_S_CHURCHILL: 'Quote from Winston S. Churchill',
  D_BRINKLEY: 'Quote from David Brinkley',
  G_CHAHAL: 'Quote from Gurbaksh Chahal',
}

export const LESSON_PAGE_EXCLUSION: LESSON_NAME[] = [
  LESSON_NAMES.LONG_WORD_H_LEFT,
  LESSON_NAMES.LONG_WORD_H_RIGHT,
  LESSON_NAMES.ONE_MIN,
  LESSON_NAMES.FIVE_MIN,
  LESSON_NAMES.TEN_MIN,
  LESSON_NAMES.FIFTEEN_MIN,
  LESSON_NAMES.M_DUNN,
  LESSON_NAMES.A_LINCOLN,
  LESSON_NAMES.M_ANGELOU,
  LESSON_NAMES.M_TERESA,
  LESSON_NAMES.J_LENNON,
  LESSON_NAMES.N_MENDELA,
  LESSON_NAMES.CONFUCIUS,
  LESSON_NAMES.T_A_EDISON,
  LESSON_NAMES.H_D_THOREAU,
  LESSON_NAMES.W_S_CHURCHILL,
  LESSON_NAMES.D_BRINKLEY,
  LESSON_NAMES.G_CHAHAL,
]

export type INITIAL_EVALUATION_LESSON_ITEMS =
  | LESSON_NAMES.UPPERCASE
  | LESSON_NAMES.LOWERCASE
  | LESSON_NAMES.NUM
  | LESSON_NAMES.PUNCTUATION
  | LESSON_NAMES.NG_BI
  | LESSON_NAMES.CW_1_50
