export const MAX_MESSAGE_LENGTH = 1000
export const MAX_MESSAGE_COUNT_IN_3_HOURS = 25

// it is for the array of messages
export const MAX_MESSAGE_LENGTH_API_REQUEST = 2000

export const EVALUATION_BUTTON_TEXT = 'Start Evaluation'
export const AI_GUIDED_LESSON_BUTTON_TEXT = 'Generate AI Guided Lesson'

export const AI_SUBSCRIPTION_TYPES = {
  BETA: 'beta',
  MONTHLY: 'monthly',
  YEARLY: 'yearly',
  TRIAL: 'trial',
} as const

export const AI_EXPIRY_AFTER_DAYS: Record<keyof typeof AI_SUBSCRIPTION_TYPES, number> = {
  BETA: 7,
  MONTHLY: 31,
  YEARLY: 365,
  TRIAL: 7,
} as const

// number of beta subscribers allowed
export const AI_BETA_SUBSCRIBERS_LIMIT = 50
