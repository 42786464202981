// credit: https://stackoverflow.com/a/51398471/7562739

import { INITIAL_EVALUATION_LESSON_ITEMS, LESSON_NAME, LESSON_NAMES } from 'constants/lessons'
import { WeaknessProfile } from 'constants/stats'
import { RESPONSE_COPY } from 'interface/api/copy'
import { extractCopy, getCopy, hasCopy, setCopies } from 'local-store/copy'
import _shuffle from 'lodash/shuffle'
import { call, put, select } from 'redux-saga/effects'
import { requestCopy, requestManyParagraph } from 'store/sagas/requests/copy'
import { pgSlices } from 'store/slices/pg-slice'
import { PayloadHandleRestart } from 'store/slices/pg-slice/interface'
import {
  selectNextTestForInitialEvaluation,
  selectRecentWpm,
  selectWeaknessItemToGenerateTest,
  selectWeaknessProfile,
  WeaknessItem,
} from 'store/slices/stat/stat'
import { assert } from 'utils/assert'

import { lowerCaseOnly } from '../../../utils/string-manipulation/lowercase-only'
import { uppercaseOnly } from '../../../utils/string-manipulation/uppercase-only'
import { requestAiCopy } from '../requests/ai/ai-copy'
import { requestInitialEvaluationCopy } from '../requests/initial-evaluation-copy'

// HIGHLIGHT: make sure the methods that saga calls are not being called from anywhere else to avoid infinite loops.

// for put effect PutEffect<PayloadAction<CopyResponse>> can be used and import be like ---> import { AnyAction } from 'redux';
type WhatYouYield = any
type WhatYouReturn = any

// what you accept as a return from requestCopy
type WhatYouAccept = any

/**
 * There are certain situation where lesson name is modified here although the payload asks for something else. For example,
 *
 * If the lesson name is ONE_MIN, TWO_MIN, FIVE_MIN, TEN_MIN, or FIFTEEN_MIN, etc then we will rename it to
 * PARAGRAPH here. This is because we are using paragraphs for ONE_MIN lesson.
 *
 * If the lesson name is UPPERCASE, then we will rename it to PARAGRAPH here. This is because we are using paragraphs for
 * UPPERCASE lesson.
 *
 * If the lesson name is LOWERCASE, then we will rename it to PARAGRAPH here. This is because we are using paragraphs for
 * LOWERCASE lesson.
 *
 * If the lesson name is AI but next test for initial evaluation is set, then return the initial evaluation lesson instead of AI copy.
 *
 * @param action
 */
export function* handleGetCopy(action: {
  payload: PayloadHandleRestart
}): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
  try {
    const originalUnMaskedLessonName = action.payload.rules.lessonName
    let lessonName = action.payload.rules.lessonName || LESSON_NAMES.PARAGRAPH

    // LESSON MASKING:
    // Setup a lesson mask. For ONE_MIN lesson, or other duration based lessons, uppercase, or lowercase rename it to PARAGRAPH here so we can
    // use paragraphs for that. We are doing it here because no local store
    // state or redux is set after this line, so we are able to do this side-effect silently
    if (
      [LESSON_NAMES.ONE_MIN, LESSON_NAMES.FIVE_MIN, LESSON_NAMES.TEN_MIN, LESSON_NAMES.FIFTEEN_MIN].includes(
        lessonName as LESSON_NAMES
      )
    ) {
      lessonName = LESSON_NAMES.PARAGRAPH
    }

    if (lessonName === LESSON_NAMES.UPPERCASE) lessonName = LESSON_NAMES.PARAGRAPH
    if (lessonName === LESSON_NAMES.LOWERCASE) lessonName = LESSON_NAMES.PARAGRAPH

    const slice = pgSlices[action.payload.pgName]

    yield put(slice.actions.setCopyLoading(true))

    /*******************************
     * if paragraph then extract it from local storage, meaning remove the
     * taken paragraph from local storage. This will help us generate
     * unique paragraph each time and also next idle time the local
     * storage will get new data through handleFetchInAdvance call.
     */
    let copy: RESPONSE_COPY[] | null = null

    if (lessonName === LESSON_NAMES.PARAGRAPH) {
      copy = extractCopy(lessonName)
    } else if (lessonName === LESSON_NAMES.AI) {
      const nextTestForInitialEvaluation = (yield select(
        selectNextTestForInitialEvaluation
      )) as INITIAL_EVALUATION_LESSON_ITEMS | null

      // if next test for initial evaluation is set, then immediately return the initial evaluation lesson item only
      if (nextTestForInitialEvaluation) {
        // HIGHLIGHT: change lesson name to initial evaluation lesson item
        copy = yield call(requestInitialEvaluationCopy, nextTestForInitialEvaluation)
      } else {
        const weaknessProfile = (yield select(selectWeaknessProfile)) as WeaknessProfile

        /**
         * TODO: now we have weaknessItemToGenerateTest on the client side as
         * suggested by src\pages\api\ai-copy\utils\get-weakness-item.ts
         * So, now, we need to send that to the server and use it there.
         */

        const weaknessItemToGenerateTest = selectWeaknessItemToGenerateTest(weaknessProfile) as WeaknessItem | null

        console.log({ weaknessItemToGenerateTest })

        const wpm = yield select(selectRecentWpm)
        const { characters, ngrams, words } = weaknessProfile
        copy = yield requestAiCopy({ wpm, characters, ngrams, words, weaknessItemToGenerateTest })
      }
    } else {
      copy = getCopy(lessonName)
    }

    // if not AI && if copy is not in local storage, fetch it from server
    if (lessonName !== LESSON_NAMES.AI && !copy?.length) {
      copy = yield call(requestCopy, lessonName)

      assert(!!copy, 'copy should be fetched from server. (copy.ts)')

      // other than paragraphs, set all other fetched copies to local-storage for future use.
      if (copy && lessonName !== 'PARAGRAPH') {
        setCopies(lessonName, copy)
      }
    }

    let text = _shuffle(copy).join(' ')

    console.log({ text })

    // if lesson is uppercase or lowercase, transform the text accordingly and we used Masked approach above
    if (originalUnMaskedLessonName === LESSON_NAMES.UPPERCASE) {
      text = uppercaseOnly(text)
    }

    if (originalUnMaskedLessonName === LESSON_NAMES.LOWERCASE) {
      text = lowerCaseOnly(text)
    }

    // shuffle every time
    yield put(slice.actions.setRules({ text }))
    yield put(slice.actions._restart())
    yield put(slice.actions.setCopyLoading(false))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}

export function* handleFetchInAdvance(): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
  try {
    const lessonName: LESSON_NAME = 'PARAGRAPH'

    if (!hasCopy(lessonName)) {
      const data = yield call(requestManyParagraph)

      if (data?.length) {
        setCopies(lessonName, data)
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}

export {}
